import React from 'react'
import star from '../../../static/images/star.png'
import actor from '../../../static/images/actor.png'

export default function Community() {
    return (
        <div className='w-full hidden'>
            <div className='container flex items-center justify-center m-auto pt-28 flex-col'>
                <div className='flex justify-center md:justify-start space-x-2'>
                    <div className='w-3 h-3 bg-[#0041E9] rounded-full' />
                    <div className='w-3 h-3 bg-[#0060F0] rounded-full' />
                    <div className='w-3 h-3 bg-[#227AFF] rounded-full' />
                    <div className='w-3 h-3 bg-[#319CFF] rounded-full' />
                </div>
                <h2 className='font-semibold text-center text-[#293F67] mt-4 text-[34px]'>
                    Reviewed by the community.
                    Trusted by professionals
                </h2>

            </div>


            <div className='flex flex-row overflow-x-scroll py-6 justify-between w-full space-x-8 mt-20 '>

                <div className='review-card'>
                    <div className='flex flex-row justify-between items-center space-x-2'>
                        <h4 className='text-[#334C78] w-4/6 font-semibold text-[18px]'>Great Templates,
                            Easy Customization</h4>
                        <p className='text-[#ADADAD] w-2/6 text-right text-[14px]'>2 hour ago</p>
                    </div>
                    <div className='flex flex-row my-4'>
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                    </div>
                    <p className='text-[#374867] text-[15px]'>
                        “It was easy for me to keep an extremely high bar in my presentation, without the head-banging of needing to design anything from scratch.”
                    </p>
                    <div className='flex flex-row mt-6 items-center'>
                        <img src={actor} alt="actor" />
                        <div className='ml-4'>
                            <h4 className='text-[#324B78] text-[15px]'>John Smith</h4>
                            <p className='text-[#757492] text-[14px]'>Software Engineer</p>
                        </div>
                    </div>
                </div>

                <div className='review-card'>
                    <div className='flex flex-row justify-between items-center space-x-2'>
                        <h4 className='text-[#334C78] w-4/6 font-semibold text-[18px]'>Great Templates,
                            Easy Customization</h4>
                        <p className='text-[#ADADAD] w-2/6 text-right text-[14px]'>2 hour ago</p>
                    </div>
                    <div className='flex flex-row my-4'>
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                    </div>
                    <p className='text-[#374867] text-[15px]'>
                        “It was easy for me to keep an extremely high bar in my presentation, without the head-banging of needing to design anything from scratch.”
                    </p>
                    <div className='flex flex-row mt-6 items-center'>
                        <img src={actor} alt="actor" />
                        <div className='ml-4'>
                            <h4 className='text-[#324B78] text-[15px]'>John Smith</h4>
                            <p className='text-[#757492] text-[14px]'>Software Engineer</p>
                        </div>
                    </div>
                </div>

                <div className='review-card'>
                    <div className='flex flex-row justify-between items-center space-x-2'>
                        <h4 className='text-[#334C78] w-4/6 font-semibold text-[18px]'>Great Templates,
                            Easy Customization</h4>
                        <p className='text-[#ADADAD] w-2/6 text-right text-[14px]'>2 hour ago</p>
                    </div>
                    <div className='flex flex-row my-4'>
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                    </div>
                    <p className='text-[#374867] text-[15px]'>
                        “It was easy for me to keep an extremely high bar in my presentation, without the head-banging of needing to design anything from scratch.”
                    </p>
                    <div className='flex flex-row mt-6 items-center'>
                        <img src={actor} alt="actor" />
                        <div className='ml-4'>
                            <h4 className='text-[#324B78] text-[15px]'>John Smith</h4>
                            <p className='text-[#757492] text-[14px]'>Software Engineer</p>
                        </div>
                    </div>
                </div>

                <div className='review-card'>
                    <div className='flex flex-row justify-between items-center space-x-2'>
                        <h4 className='text-[#334C78] w-4/6 font-semibold text-[18px]'>Great Templates,
                            Easy Customization</h4>
                        <p className='text-[#ADADAD] w-2/6 text-right text-[14px]'>2 hour ago</p>
                    </div>
                    <div className='flex flex-row my-4'>
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                    </div>
                    <p className='text-[#374867] text-[15px]'>
                        “It was easy for me to keep an extremely high bar in my presentation, without the head-banging of needing to design anything from scratch.”
                    </p>
                    <div className='flex flex-row mt-6 items-center'>
                        <img src={actor} alt="actor" />
                        <div className='ml-4'>
                            <h4 className='text-[#324B78] text-[15px]'>John Smith</h4>
                            <p className='text-[#757492] text-[14px]'>Software Engineer</p>
                        </div>
                    </div>
                </div>

            </div>

            <div className='flex flex-row overflow-x-scroll py-6 justify-between w-full space-x-8 mt-20 '>

                <div className='review-card'>
                    <div className='flex flex-row justify-between items-center space-x-2'>
                        <h4 className='text-[#334C78] w-4/6 font-semibold text-[18px]'>Great Templates,
                            Easy Customization</h4>
                        <p className='text-[#ADADAD] w-2/6 text-right text-[14px]'>2 hour ago</p>
                    </div>
                    <div className='flex flex-row my-4'>
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                    </div>
                    <p className='text-[#374867] text-[15px]'>
                        “It was easy for me to keep an extremely high bar in my presentation, without the head-banging of needing to design anything from scratch.”
                    </p>
                    <div className='flex flex-row mt-6 items-center'>
                        <img src={actor} alt="actor" />
                        <div className='ml-4'>
                            <h4 className='text-[#324B78] text-[15px]'>John Smith</h4>
                            <p className='text-[#757492] text-[14px]'>Software Engineer</p>
                        </div>
                    </div>
                </div>

                <div className='review-card'>
                    <div className='flex flex-row justify-between items-center space-x-2'>
                        <h4 className='text-[#334C78] w-4/6 font-semibold text-[18px]'>Great Templates,
                            Easy Customization</h4>
                        <p className='text-[#ADADAD] w-2/6 text-right text-[14px]'>2 hour ago</p>
                    </div>
                    <div className='flex flex-row my-4'>
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                    </div>
                    <p className='text-[#374867] text-[15px]'>
                        “It was easy for me to keep an extremely high bar in my presentation, without the head-banging of needing to design anything from scratch.”
                    </p>
                    <div className='flex flex-row mt-6 items-center'>
                        <img src={actor} alt="actor" />
                        <div className='ml-4'>
                            <h4 className='text-[#324B78] text-[15px]'>John Smith</h4>
                            <p className='text-[#757492] text-[14px]'>Software Engineer</p>
                        </div>
                    </div>
                </div>

                <div className='review-card'>
                    <div className='flex flex-row justify-between items-center space-x-2'>
                        <h4 className='text-[#334C78] w-4/6 font-semibold text-[18px]'>Great Templates,
                            Easy Customization</h4>
                        <p className='text-[#ADADAD] w-2/6 text-right text-[14px]'>2 hour ago</p>
                    </div>
                    <div className='flex flex-row my-4'>
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                    </div>
                    <p className='text-[#374867] text-[15px]'>
                        “It was easy for me to keep an extremely high bar in my presentation, without the head-banging of needing to design anything from scratch.”
                    </p>
                    <div className='flex flex-row mt-6 items-center'>
                        <img src={actor} alt="actor" />
                        <div className='ml-4'>
                            <h4 className='text-[#324B78] text-[15px]'>John Smith</h4>
                            <p className='text-[#757492] text-[14px]'>Software Engineer</p>
                        </div>
                    </div>
                </div>

                <div className='review-card'>
                    <div className='flex flex-row justify-between items-center space-x-2'>
                        <h4 className='text-[#334C78] w-4/6 font-semibold text-[18px]'>Great Templates,
                            Easy Customization</h4>
                        <p className='text-[#ADADAD] w-2/6 text-right text-[14px]'>2 hour ago</p>
                    </div>
                    <div className='flex flex-row my-4'>
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                    </div>
                    <p className='text-[#374867] text-[15px]'>
                        “It was easy for me to keep an extremely high bar in my presentation, without the head-banging of needing to design anything from scratch.”
                    </p>
                    <div className='flex flex-row mt-6 items-center'>
                        <img src={actor} alt="actor" />
                        <div className='ml-4'>
                            <h4 className='text-[#324B78] text-[15px]'>John Smith</h4>
                            <p className='text-[#757492] text-[14px]'>Software Engineer</p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}
