import React from 'react';
import { useTranslation } from 'react-i18next';
import easyTous from '../../../static/images/easy-to-use.svg';
import template from '../../../static/images/template.svg';
import proResume from '../../../static/images/pro-resume.svg';
import exportImg from '../../../static/images/export.svg';

export default function Services() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col">
      <div className="flex bg-left bg-no-repeat bg-contain py-10 md:py-40">
        <div className="flex  container flex-col md:flex-row">
          <div className="flex flex-row flex-wrap m-4 space-y-6 space-x-0 md:space-x-6 md:space-y-0 justify-center">
            <div className="service-box">
              <div className="bg-[#3AB4FF] flex items-center justify-center  rounded-2xl p-2  my-8 w-[60px] h-[60px]">
                <img src={exportImg} alt="service-1" />
              </div>
              <div>
                <div className="text-[#293F67] font-semibold mb-6 text-[14px] md:text-[18px]">
                  {t('homepage.servicesSection.services.0.title')}
                </div>
                <div className="text-[#506180] font-normal mb-8 text-[14px] md:text-[16px]">
                  {t('homepage.servicesSection.services.0.description')}
                </div>
              </div>
            </div>

            <div className="service-box">
              <div className="bg-[#3346D3] flex items-center justify-center  rounded-2xl p-2  my-8 w-[60px] h-[60px]">
                <img src={template} alt="service-1" />
              </div>
              <div>
                <div className="text-[#293F67] font-semibold mb-6 text-[14px] md:text-[18px]">
                  {t('homepage.servicesSection.services.1.title')}
                </div>
                <div className="text-[#506180] font-normal mb-8 text-[14px] md:text-[16px]">
                  {t('homepage.servicesSection.services.1.description')}
                </div>
              </div>
            </div>

            <div className="service-box">
              <div className="bg-[#F79C56] flex items-center justify-center  rounded-2xl p-2  my-8 w-[60px] h-[60px]">
                <img src={proResume} alt="service-1" />
              </div>
              <div>
                <div className="text-[#293F67] font-semibold mb-6 text-[14px] md:text-[18px]">
                  {t('homepage.servicesSection.services.2.title')}
                </div>
                <div className="text-[#506180] font-normal mb-8 text-[14px] md:text-[16px]">
                  {t('homepage.servicesSection.services.2.description')}
                </div>
              </div>
            </div>

            <div className="service-box">
              <div className="bg-[#F7515C] flex items-center justify-center  rounded-2xl p-2 my-8 w-[60px] h-[60px]">
                <img src={easyTous} alt="service-1" />
              </div>
              <div>
                <div className="text-[#293F67] font-semibold mb-6 text-[14px] md:text-[18px]">
                  {t('homepage.servicesSection.services.3.title')}
                </div>
                <div className="text-[#506180] font-normal mb-8 text-[14px] md:text-[16px]">
                  {t('homepage.servicesSection.services.3.description')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
