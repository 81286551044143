import React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Resume from '../../../static/images/resume-maker.png';

export default function UseTemplate({ user, handleLogin }) {
  const { t } = useTranslation();
  return (
    <div className="flex container mt-[50px] md:mt-[80px]">
      <div className="w-full md:w-2/4 flex flex-col justify-center items-left ml-6">
        <div className="flex flex-col w-full md:w-11/12 mt-2">
          <h2 className="text-[#293F67] font-semibold text-[28px] text-center md:text-[34px] md:text-left mt-6">
            {' '}
            {t('homepage.useSection.title')}
          </h2>
          <p className="font-normal text-[14px] md:text-[18px] text-[#7C8296]">
            {t('homepage.useSection.description')}
          </p>
          <div className="mt-12 flex space-x-3 items-center">
            {user ? (
              <Link
                to="/template"
                className="bg-[#3346D3]  w-[214px] m-auto mt-8 md:m-0 md:mt-8 text-[16.5px] text-center py-6 rounded-2xl text-white hover:no-underline"
              >
                {t('shared.buttons.create')}
              </Link>
            ) : (
              <button
                onClick={(e) => handleLogin(e)}
                className="bg-[#3346D3]  w-[214px] m-auto mt-8 md:m-0 md:mt-8 text-[16.5px] text-center py-6 rounded-2xl text-white hover:no-underline font-semibold"
              >
                {t('shared.buttons.create')}
              </button>
            )}{' '}
          </div>
        </div>
      </div>
      <div className="hidden md:block w-2/4">
        <img src={Resume} alt="header" className="w-full" />
      </div>
    </div>
  );
}
