import { Helmet } from 'react-helmet';
import React, { memo, useContext, useEffect } from 'react';
// import { firebase } from '@firebase/app'
import { useTranslation } from 'react-i18next';
import firebase from 'gatsby-plugin-firebase';
import { languagedetector } from '../i18n';
import Wrapper from '../components/shared/Wrapper';
import NavBar from '../components/global/NavBar';
import HeroSection from '../components/homepage/HeroSection';
import WhyChooseUs from '../components/homepage/WhyChooseUs';
import UseTemplate from '../components/homepage/UseTemplate';
import Services from '../components/homepage/Services';
import Creatives from '../components/homepage/Creatives';
import Community from '../components/homepage/Community';
import JoinSection from '../components/homepage/JoinSection';
import Footer from '../components/global/Footer';
import ModalContext from '../contexts/ModalContext';
import UserContext from '../contexts/UserContext';
// import firebaseConfig from '../utils/firebaseConfig';

const Home = () => {
  const { emitter, events } = useContext(ModalContext);
  const { user } = useContext(UserContext);
  const handleLogin = () => emitter.emit(events.AUTH_MODAL);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.fbq != null) {
        if (process.env.NODE_ENV || process.env.NODE_ENV !== 'development')
          window.fbq('trackCustom', 'visited_homepage', {});
      }
    }
  }, []);
  useEffect(() => {
    if (!firebase) {
      return;
    }
    if (process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
      firebase.analytics().logEvent('visited_homepage');
    }
  }, [firebase]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const userLang =
        window.navigator.language || window.navigator.userLanguage;

      const userLangTrimmed = userLang.trim();
      const userLangCleaned = userLangTrimmed.split(/-|_/)[0];

      i18n.changeLanguage(
        languagedetector.includes(userLangCleaned) ? userLangCleaned : 'en',
      );
      localStorage.setItem(
        'language',
        languagedetector.includes(userLangCleaned) ? userLangCleaned : 'en',
      );
    }
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>SimpleCV - Create Your Resume Quickly</title>
        <link rel="canonical" href="https://simplecv.me/" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />

        {/* <meta httpEquiv="Content-Security-Policy" content="upgrade-insecure-requests" /> */}
      </Helmet>

      <NavBar />
      <HeroSection user={user} handleLogin={handleLogin} />
      <WhyChooseUs user={user} handleLogin={handleLogin} />
      <UseTemplate user={user} handleLogin={handleLogin} />
      <Services />
      <Creatives />
      <Community />
      <JoinSection user={user} handleLogin={handleLogin} />
      <Footer />
    </Wrapper>
  );
};

export default memo(Home);
