import React from 'react';
import { Link } from 'gatsby';
// import buttonIcon from '../../../static/images/button-icon.svg'
import { useTranslation } from 'react-i18next';
import HeaderImage from '../../../static/images/header-image.png';

export default function HeroSection({ user, handleLogin }) {
  const { t } = useTranslation();

  return (
    <div className="flex container mt-[60px] md:mt-[140px]">
      <div className="w-full md:w-2/4 flex flex-col justify-center items-left p-4 md:p-0 ml-0 md:ml-6">
        <div className="flex space-x-4 bg-[#e4e4eb] items-center rounded-full p-2 w-11/12 md:w-8/12">
          <p className="font-semibold flex items-center justify-center text-[14px] w-10 h-10 md:w-[22px] md:h-[22px] text-center text-[#152B7C] bg-[#21E8B8] rounded-full">
            $
          </p>
          <p className="text-[#3346D3] text-[10px] md:text-[14px] font-semibold">
            {t('homepage.heroSection.subtitle')}
          </p>
        </div>
        <div className="flex flex-col w-11/12 mt-2">
          <h1 className="font-bold text-[28px] md:text-[42px] text-left text-[#152B7C] ">
            {t('homepage.heroSection.title')}
          </h1>
          <p className="font-normal text-[14px] md:text-[18px] text-[#7C8296]">
            {t('homepage.heroSection.descriptions.0')}
            <br />
            {t('homepage.heroSection.descriptions.1')}
            <br />
            {t('homepage.heroSection.descriptions.2')}
          </p>
          <div className="mt-12 flex space-x-3 items-center">
            {user ? (
              <Link
                to="/template"
                className="bg-[#3346D3] w-[214px] text-[14px] md:text-[16.5px] text-center py-6 rounded-2xl text-white hover:no-underline"
              >
                {t('shared.buttons.create')}
              </Link>
            ) : (
              <button
                onClick={(e) => handleLogin(e)}
                className="bg-[#3346D3] w-[214px] text-[14px] md:text-[16.5px] text-center py-6 rounded-2xl text-white hover:no-underline font-semibold"
              >
                {t('shared.buttons.create')}
              </button>
            )}
            {/* <Link to='/' className='border-[1px] flex justify-center text-[14px] md:text-[16.5px] py-[14px] md:py-[16px] items-center border-solid text-[#152B7C] w-[214px] text-center  border-[#BCC5E8] space-x-4 rounded-2xl hover:no-underline '><img src={buttonIcon} alt='icon' /><span>Watch Video</span></Link> */}
          </div>
        </div>
      </div>
      <div className="hidden md:block w-2/4">
        <img src={HeaderImage} alt="header" className="w-full" />
      </div>
    </div>
  );
}
