import React from 'react';
import { Link } from 'gatsby';
// import company1 from '../../../static/images/company-2.svg'
// import company2 from '../../../static/images/company-1.svg'
// import company3 from '../../../static/images/company-3.svg'
import { useTranslation } from 'react-i18next';
import background from '../../../static/images/background-circle.png';
import service1 from '../../../static/images/service-1.svg';
import service2 from '../../../static/images/service-2.svg';
import service3 from '../../../static/images/service-3.svg';

export default function WhyChooseUs({ user, handleLogin }) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col">
      {/* <div className='flex flex-wrap justify-center space-x-0 md:space-x-12 mt-28 border-y-[1px] border-solid border-[#BCC5E8] py-5'>
                <img src={company1} alt='company1' />
                <img src={company2} alt='company2' />
                <img src={company3} alt='company3' />
            </div> */}
      <div
        className="flex bg-left bg-no-repeat bg-contain py-10 md:py-60"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="flex  container flex-col md:flex-row">
          <div className="flex w-full md:w-2/4 flex-col space-y-8 justify-center">
            <div className="flex items-center space-x-6 bg-white w-full md:w-10/12 px-10 py-6 rounded-xl shadow-lg">
              <div className="bg-[#E8F3FD] flex items-center justify-center rounded-full p-2 w-[60px] h-[60px]">
                <img src={service1} alt="service-1" />
              </div>
              <div>
                <div className="text-[#324B78] font-semibold text-[16px] md:text-[20px]">
                  {t('homepage.whyChooseUsSection.features.0.title')}
                </div>
                <div className="text-[#506180] font-normal text-[14px] md:text-[16px]">
                  {t('homepage.whyChooseUsSection.features.0.description')}
                </div>
              </div>
            </div>

            <div className="flex items-center space-x-6 bg-white w-full md:w-10/12 px-10 py-6 rounded-xl shadow-lg">
              <div className="bg-[#FFF5EA] flex items-center justify-center rounded-full p-2 w-[60px] h-[60px]">
                <img src={service2} alt="service-2" />
              </div>
              <div>
                <div className="text-[#324B78] font-semibold text-[16px] md:text-[20px]">
                  {t('homepage.whyChooseUsSection.features.1.title')}
                </div>
                <div className="text-[#506180] font-normal text-[14px] md:text-[16px]">
                  {t('homepage.whyChooseUsSection.features.1.description')}
                </div>
              </div>
            </div>

            <div className="flex items-center space-x-6 bg-white w-full md:w-10/12 px-10 py-6 rounded-xl shadow-lg">
              <div className="bg-[#ECF7F3] flex items-center justify-center rounded-full p-2 w-[60px] h-[60px]">
                <img src={service3} alt="service-3" />
              </div>
              <div>
                <div className="text-[#324B78] font-semibold text-[16px] md:text-[20px]">
                  {t('homepage.whyChooseUsSection.features.2.title')}
                </div>
                <div className="text-[#506180] font-normal text-[14px] md:text-[16px]">
                  {t('homepage.whyChooseUsSection.features.2.description')}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-2/4 mt-16 md:mt-0">
            <div className="flex flex-col w-full md:w-10/12 ">
              <div className="flex justify-center md:justify-start space-x-2">
                <div className="w-3 h-3 bg-[#0041E9] rounded-full" />
                <div className="w-3 h-3 bg-[#0060F0] rounded-full" />
                <div className="w-3 h-3 bg-[#227AFF] rounded-full" />
                <div className="w-3 h-3 bg-[#319CFF] rounded-full" />
              </div>
              <h2 className="text-[#293F67] font-semibold text-[28px] text-center md:text-[34px] md:text-left mt-6">
                {t('homepage.whyChooseUsSection.title')}
              </h2>
              <p className="text-[#506180] font-normal p-6 md:p-0 text-[16px] md:text-[20px] text-left mt-3">
                {t('homepage.whyChooseUsSection.descriptions.0')}

                <br />
                <br />
                {t('homepage.whyChooseUsSection.descriptions.1')}
              </p>
              {user ? (
                <Link
                  to="/template"
                  className="bg-[#3346D3]  w-[214px] m-auto mt-8 md:m-0 md:mt-8 text-[16.5px] text-center py-6 rounded-2xl text-white hover:no-underline"
                >
                  {t('shared.buttons.create')}
                </Link>
              ) : (
                <button
                  onClick={(e) => handleLogin(e)}
                  className="bg-[#3346D3]  w-[214px] m-auto mt-8 md:m-0 md:mt-8 text-[16.5px] text-center py-6 rounded-2xl text-white hover:no-underline font-semibold"
                >
                  {t('shared.buttons.create')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
