import { Link } from 'gatsby'
import React from 'react'

export default function JoinSection({ user, handleLogin }) {
    return (
        <div className='w-full bg-[#F2F5FA] pb-20 hidden'>
            <div className='container flex items-center justify-center m-auto pt-28 flex-col'>
                <div className='flex justify-center md:justify-start space-x-2'>
                    <div className='w-3 h-3 bg-[#0041E9] rounded-full' />
                    <div className='w-3 h-3 bg-[#0060F0] rounded-full' />
                    <div className='w-3 h-3 bg-[#227AFF] rounded-full' />
                    <div className='w-3 h-3 bg-[#319CFF] rounded-full' />
                </div>
                <h2 className='font-semibold text-center text-[#3346D3] mt-6 text-[34px]'>
                    Join over 14,442,444 users<br />from worldwide
                </h2>
                <p className='text-[#334C78] text-center mt-4 text-[24px]'>Start for free - try our resume builder now</p>
                {user ?
                    <Link to='/template' className='bg-[#3346D3]  w-[214px] m-auto mt-8 md:m-0 md:mt-8 text-[16.5px] text-center py-6 rounded-2xl text-white hover:no-underline'>Create My Resume</Link>
                    :
                    <button onClick={(e) => handleLogin(e)} className='bg-[#3346D3]  w-[214px] m-auto mt-8 md:m-0 md:mt-8 text-[16.5px] text-center py-6 rounded-2xl text-white hover:no-underline font-semibold'>Create My Resume</button>

                }
            </div>
        </div>
    )
}
