import React from 'react';
import { useTranslation } from 'react-i18next';
import slider1 from '../../../static/images/slider1.png';
import slider2 from '../../../static/images/slider2.png';
import slider3 from '../../../static/images/slider3.png';
import slider4 from '../../../static/images/slider4.png';

export default function Creatives() {
  const { t } = useTranslation();
  return (
    <div className="bg-[#3346D3] w-full">
      <div className="container flex items-center justify-center m-auto pt-20 flex-col">
        <div className="flex justify-center md:justify-start space-x-2">
          <div className="w-3 h-3 bg-[#FFFFFF] rounded-full" />
          <div className="w-3 h-3 bg-[#C5CCFF] rounded-full" />
          <div className="w-3 h-3 bg-[#8C9AFF] rounded-full" />
          <div className="w-3 h-3 bg-[#5A6EFF] rounded-full" />
        </div>
        <h2 className="font-semibold text-center text-white mt-4 text-[34px]">
          {t('homepage.creativesSection.title')}
        </h2>
        <p className="text-[#D4D8F1] text-center mt-2 px-8 md:px-28 text-[22px] ">
          {t('homepage.creativesSection.description')}
        </p>
      </div>

      <div className="flex flex-row items-end overflow-x-scroll justify-start md:justify-between w-full space-x-8 pb-2 mt-20 ">
        <img src={slider2} alt="slider2" />
        <img src={slider1} alt="slider1" />
        <img src={slider3} alt="slider3" />
        <img src={slider4} alt="slider4" />
      </div>
    </div>
  );
}
